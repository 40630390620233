<template>
  <div class="map" v-loading="loading" element-loading-text="拼命加载中">
    <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="center" :zoom="zoom" @ready="handler">
    </baidu-map>
  </div>
</template>

<script>
export default {
  data () {
    return {
      center: {
        lng: 115.0196282584,
        lat: 27.1058914875
      },
      zoom: 11,
      areaCode: '',
      typeId: 1,
      deviceList: [],
      loading: true,
      point: null,
      marker: null,
      markerIcon: null,
      selfMarker: null,
      map: null
    }
  },
  created () {
    this.areaCode = this.$route.query.areaCode || 360822
    this.typeId = this.$route.query.typeId || 15
    this.getDeviceList()
  },
  methods: {
    // 地图初始化
    handler ({ BMap, map }) {
      this.map = map
      this.point = (lng, lat) => {
        return new BMap.Point(lng, lat)
      }
      this.marker = (obj) => {
        return new BMap.Marker(obj)
      }
      this.markerIcon = (imgUrl, num1, num2) => {
        return new BMap.Icon(imgUrl, new BMap.Size(num1, num2))
      }
      this.selfMarker = (pt, myIcon) => {
        return new BMap.Marker(pt, { icon: myIcon })
      }
      // 地图的初始中心点
      const point = this.point(this.center.lng, this.center.lat)
      this.map.centerAndZoom(point, 15)
    },
    async getDeviceList () {
      const data = {
        areaCode: this.areaCode,
        typeId: this.typeId
      }
      const { data: result } = await this.$axios.get('/equipment/listForOutSys', { params: data })
      if (result.code === 200) {
        this.deviceList = result.data
        const point = this.point(this.deviceList[0].lng, this.deviceList[0].lat)
        this.map.centerAndZoom(point, 11)
        this.drawPoint()
        this.loading = false
      }
    },
    drawPoint () {
      this.deviceList.forEach(item => {
        let marker = null
        const point = this.point(item.lng, item.lat)
        if (this.typeId === 15) {
          const myIcon = this.markerIcon(require('../../assets/img/fireWater.png'), 50, 50)
          marker = this.selfMarker(point, myIcon)
        } else if (this.typeId < 15) {
          const myIcon = this.markerIcon(require(`../../assets/img/${this.typeId}.png`), 50, 50)
          marker = this.selfMarker(point, myIcon)
        } else {
          marker = this.marker(point)
        }
        this.map.addOverlay(marker)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  height: 100vh;
  width: 100%;
  position: relative;
  .bmView {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
</style>
